import { DEFAULT_BINANCE_TOKEN_LIST, GENIE_TOKEN } from 'lib/hooks/useTokenList/fetchTokenList'

import { FungibleToken } from '../../types'

const TOKEN_API_URL = process.env.REACT_APP_TEMP_API_URL

export const fetchSearchTokens = async (tokenQuery: string, connectedChainName: string): Promise<FungibleToken[]> => {
  if (!TOKEN_API_URL) return Promise.resolve([])
  const url = `${TOKEN_API_URL}/tokens/search?tokenQuery=${tokenQuery}`
  const tokenQueryLower = tokenQuery.toLowerCase()
  let r
  let data

  if (connectedChainName === 'BNB') {
    data = await (await fetch(DEFAULT_BINANCE_TOKEN_LIST)).json()

    if (data.tokens) {
      data.tokens.push(GENIE_TOKEN)
    }

    return data.tokens
      ? data.tokens
          .filter((t: FungibleToken) => {
            return t.chainId === 56 || t.symbol === 'GENIE'
          })
          .filter((t: FungibleToken) => t.symbol !== 'WETH' && t.symbol !== 'GNP')
          .filter((t: FungibleToken) => {
            return ~t.symbol.toLowerCase().indexOf(tokenQueryLower) || ~t.name.toLowerCase().indexOf(tokenQueryLower)
          })
      : []
  } else {
    r = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    data = await r.json()

    if (data.data) {
      data.data.push(GENIE_TOKEN)
    }

    // TODO Undo favoritism
    return data.data
      ? data.data
          .sort((a: FungibleToken, b: FungibleToken) => (b.name === 'GenieSwap' ? 1 : b.volume24h - a.volume24h))
          .filter((t: FungibleToken) => t.symbol !== 'WETH' && t.symbol !== 'WBNB')
      : []
  }
}
