import 'react';
export { D as DEFAULT_LOCALE, E as EMPTY_TOKEN_LIST, F as Field, d as SUPPORTED_LOCALES, S as SupportedChainId, b as SwapWidget, c as SwapWidgetSkeleton, T as TransactionType, U as UNISWAP_TOKEN_LIST, f as darkTheme, g as defaultTheme, h as invertTradeType, l as lightTheme, t as toTradeType, v as validateTokenList, e as validateTokens } from './index-19d12e88.js';
export { TradeType } from '@uniswap/sdk-core';
import 'buffer';
import '@babel/runtime/helpers/slicedToArray';
import '@babel/runtime/helpers/defineProperty';
import 'styled-components';
import '@babel/runtime/helpers/taggedTemplateLiteral';
import 'react-feather';
import '@web3-react/core';
import 'polished';
import 'wcag-contrast';
import '@babel/runtime/helpers/extends';
import 'rebass';
import '@babel/runtime/helpers/objectWithoutProperties';
import '@babel/runtime/helpers/asyncToGenerator';
import '@babel/runtime/regenerator';
import 'jotai/utils';
import 'jotai';
import 'wicg-inert';
import 'react-dom';
import 'tiny-invariant';
import '@babel/runtime/helpers/classCallCheck';
import '@babel/runtime/helpers/createClass';
import '@babel/runtime/helpers/assertThisInitialized';
import '@babel/runtime/helpers/get';
import '@babel/runtime/helpers/inherits';
import '@babel/runtime/helpers/possibleConstructorReturn';
import '@babel/runtime/helpers/getPrototypeOf';
import '@babel/runtime/helpers/toConsumableArray';
import '@ethersproject/providers';
import '@ethersproject/properties';
import '@reduxjs/toolkit';
import '@web3-react/walletconnect-v2';
import 'qrcode';
import '@babel/runtime/helpers/typeof';
import '@reduxjs/toolkit/query/react';
import 'jotai/immer';
import '@ethersproject/units';
import 'jsbi';
import '@uniswap/v2-sdk';
import '@uniswap/v3-sdk';
import '@uniswap/router-sdk';
import 'qs';
import '@ethersproject/abi';
import '@uniswap/redux-multicall';
import '@ethersproject/address';
import '@ethersproject/constants';
import '@ethersproject/contracts';
import '@ethersproject/hash';
import '@babel/runtime/helpers/wrapNativeSuper';
import '@ethersproject/bytes';
import '@ethersproject/bignumber';
import '@ethersproject/strings';
import 'cids';
import 'multicodec';
import 'multihashes';
import 'popper-max-size-modifier';
import 'react-popper';
import 'node-vibrant/lib/bundle.js';
import 'setimmediate';
import 'react-virtualized-auto-sizer';
import 'react-window';
import '@web3-react/eip1193';
import '@web3-react/metamask';
import '@web3-react/network';
import '@web3-react/types';
import 'make-plural/plurals';
import 'react-redux';
import 'redux';
