import { useWeb3React } from '@web3-react/core'
import { filterTimeAtom } from 'components/Tokens/state'
import TokenDetails from 'components/Tokens/TokenDetails'
import { TokenDetailsPageSkeleton } from 'components/Tokens/TokenDetails/Skeleton'
import { SupportedChainId } from 'constants/chains'
import { nativeOnChain } from 'constants/tokens'
import { TokenQuery, tokenQuery } from 'graphql/data/Token'
import { TokenPriceQuery, tokenPriceQuery } from 'graphql/data/TokenPrice'
import {
  CHAIN_NAME_TO_CHAIN_ID,
  chainIdToBackendName,
  TimePeriod,
  toHistoryDuration,
  validateUrlChainParam,
} from 'graphql/data/util'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { useAtomValue } from 'jotai/utils'
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryLoader } from 'react-relay'
import { Navigate, useLocation, useParams } from 'react-router-dom'

// Redirects to swap but only replace the pathname
export function RedirectPathToSwapOnly() {
  const location = useLocation()
  return <Navigate to={{ ...location, pathname: '/swap' }} replace />
}

// Redirects to native interface with
export function RedirectPathToSwapTokens() {
  const location = useLocation()
  return <Navigate to={{ ...location, pathname: '/swaptokens' }} replace />
}

export function RedirectPathToChain() {
  const params = useParams<{ chain?: string }>()

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState<SupportedChainId | undefined>(undefined)

  // Define setChain (so we can switch to required chain from parameter if required)

  const setChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      await selectChain(targetChainId)
    },
    [selectChain]
  )

  const tokenAddress = 'NATIVE'
  let { chainId: connectedChainId } = useWeb3React()

  if (params.chain) {
    connectedChainId = CHAIN_NAME_TO_CHAIN_ID[params.chain.toUpperCase()]
  }

  const chainName = chainIdToBackendName(connectedChainId).toLowerCase()
  const chain = validateUrlChainParam(chainName)
  const pageChainId = CHAIN_NAME_TO_CHAIN_ID[chain]
  const isNative = true
  const timePeriod = useAtomValue(filterTimeAtom)
  const [contract, duration] = useMemo(
    () => [
      { address: isNative ? nativeOnChain(pageChainId).wrapped.address : tokenAddress ?? '', chain },
      toHistoryDuration(timePeriod),
    ],
    [chain, isNative, pageChainId, timePeriod, tokenAddress]
  )

  const [tokenQueryReference, loadTokenQuery] = useQueryLoader<TokenQuery>(tokenQuery)
  const [priceQueryReference, loadPriceQuery] = useQueryLoader<TokenPriceQuery>(tokenPriceQuery)

  useEffect(() => {
    loadTokenQuery({ contract })
    loadPriceQuery({ contract, duration })
  }, [contract, duration, loadPriceQuery, loadTokenQuery, timePeriod])

  const refetchTokenPrices = useCallback(
    (t: TimePeriod) => {
      loadPriceQuery({ contract, duration: toHistoryDuration(t) })
    },
    [contract, loadPriceQuery]
  )

  if (!tokenQueryReference) {
    return <TokenDetailsPageSkeleton />
  }

  setTimeout(() => {
    setChain(connectedChainId as SupportedChainId)
  }, 100)

  return (
    <Suspense fallback={<TokenDetailsPageSkeleton />}>
      <TokenDetails
        urlAddress={tokenAddress}
        chain={chain}
        tokenQueryReference={tokenQueryReference}
        priceQueryReference={priceQueryReference}
        refetchTokenPrices={refetchTokenPrices}
      />
    </Suspense>
  )
}

export function RedirectPathToNativeNoURL() {
  const tokenAddress = 'NATIVE'
  const { chainId: connectedChainId } = useWeb3React()
  const chainName = chainIdToBackendName(connectedChainId).toLowerCase()
  const chain = validateUrlChainParam(chainName)
  const pageChainId = CHAIN_NAME_TO_CHAIN_ID[chain]
  const isNative = true
  const timePeriod = useAtomValue(filterTimeAtom)
  const [contract, duration] = useMemo(
    () => [
      { address: isNative ? nativeOnChain(pageChainId).wrapped.address : tokenAddress ?? '', chain },
      toHistoryDuration(timePeriod),
    ],
    [chain, isNative, pageChainId, timePeriod, tokenAddress]
  )

  const [tokenQueryReference, loadTokenQuery] = useQueryLoader<TokenQuery>(tokenQuery)
  const [priceQueryReference, loadPriceQuery] = useQueryLoader<TokenPriceQuery>(tokenPriceQuery)

  useEffect(() => {
    loadTokenQuery({ contract })
    loadPriceQuery({ contract, duration })
  }, [contract, duration, loadPriceQuery, loadTokenQuery, timePeriod])

  const refetchTokenPrices = useCallback(
    (t: TimePeriod) => {
      loadPriceQuery({ contract, duration: toHistoryDuration(t) })
    },
    [contract, loadPriceQuery]
  )

  if (!tokenQueryReference) {
    return <TokenDetailsPageSkeleton />
  }

  return (
    <Suspense fallback={<TokenDetailsPageSkeleton />}>
      <TokenDetails
        urlAddress={tokenAddress}
        chain={chain}
        tokenQueryReference={tokenQueryReference}
        priceQueryReference={priceQueryReference}
        refetchTokenPrices={refetchTokenPrices}
      />
    </Suspense>
  )
}

// Redirects from the /swap/:outputCurrency path to the /swap?outputCurrency=:outputCurrency format
export function RedirectToSwap() {
  const location = useLocation()
  const { search } = location
  const { outputCurrency } = useParams<{ outputCurrency: string }>()

  return (
    <Navigate
      to={{
        ...location,
        pathname: '/swap',
        search:
          search && search.length > 1
            ? `${search}&outputCurrency=${outputCurrency}`
            : `?outputCurrency=${outputCurrency}`,
      }}
      replace
    />
  )
}
